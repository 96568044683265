import { ReactNode } from "react";
import { Base } from "./styles";

/********************************************** */
// Row Item
/********************************************** */

interface HomePageTextProps {
  children: ReactNode;
}
const HomePageText = ({
  children
}: HomePageTextProps) => <Base data-sentry-element="Base" data-sentry-component="HomePageText" data-sentry-source-file="index.tsx">{children}</Base>;
export default HomePageText;